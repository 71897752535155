@import url('https://fonts.googleapis.com/css?family=Arimo&display=swap');
body {
	font-smooth: always;
	scroll-behavior: smooth;
	overflow-x: hidden;
    font-family: 'Helvetica', sans-serif;
	._content {
		@apply mt-0;
		@screen md {
			@apply mt-20;
		}
	}
}


.swal-modal {
   font-family:'Helvetica', sans-serif; 
}
.swal-button {
	padding: 7px 19px;
	border-radius: 2px;
	background-color: #3C6EED;
	font-size: 12px;
    border-radius:10px;
	text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }

  .swal-text {
    font-weight: bold;
	color:#26314E;
  }