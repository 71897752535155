.Header {
  position: relative;
  height: 80vh;
  display: block;

  .Header-background {
    position: absolute;
    top: -4px;

    @screen md {
      top: -23px;
    }

    @screen lg {
      top: -4px;
    }

    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #26314e;
    transform-origin: top left;
    transform: skewY(-9deg);
    overflow: hidden;
    z-index: -1;
  }

  .Header-content {
    text-align: center;
    padding: 3rem 4rem 0rem;
    margin: 0 auto;
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
      button#button-hero {
        display: none;
      }
    }

    @screen lg {
      width: 50%;
      margin: 0 0;
      text-align: left;
      padding: 6rem 3rem 4rem 8rem;
      max-width: 54rem;
      display: flex;
      justify-content: space-between;
    }

    @screen xl {
      padding: 8rem 3rem 4rem 8rem;
    }

    .Header-hero {
      text-align: left;

      h1 {
        font-family: "Arimo", sans-serif;
        font-size: 40px;
        @screen sm {
          font-size: 48px;
        }
      }

      p {
        font-family: "Arimo", sans-serif;
        font-size: 18px;
      }

      button {
        font-size: 14px;
        border-radius: 10px;
        font-family: Helvetica, sans-serif;
      }
    }
  }

  img {
    position: absolute;
    z-index: -2;
    top: 14rem;
    width: 100%;
    padding-bottom: 32px;

    @media only screen and (min-width: 375px) and (max-width: 767px) {
      top: 16rem;
    }
  }
}

.box-square {
  width: 40vh;
  height: 40vh;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 30vh;
    height: 30vh;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    width: 30vh;
    height: 30vh;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: 50vh;
    height: 50vh;
  }

  @screen xl {
    width: 50vh;
    height: 50vh;
    top: 8rem;
  }

  position: relative;
  top: 7rem;

  img {
    z-index: 0;
    width: 50%;
    position: absolute;
    left: 58%;
    top: 40%;

    @screen xxl {
      left: 75%;
    }
  }

  p {
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left: 11rem;
    top: 8rem;
    font-size: 12px;
  }
  .square {
    background-color: #3c6eed;
    position: relative;
    text-align: center;
    border-radius: 250px 15px 15px;
    transform: rotate(-35deg);
    width: 100%;
    height: 92%;
    @screen xxl {
      left: 5rem;
    }
  }
  .content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("../media/images/Desktop_Image.png");
    background-repeat: no-repeat;
    background-size: contain;
    @screen xxl {
      left: 5rem;
    }
  }
}

//Iphone 5  landscape

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
  #mobile-image {
    display: none;
  }
  .Header .Header-content {
    padding: 2rem 7rem 0rem 3rem;
    & .Header-hero p {
      font-size: 1rem;
    }
  }
}

//Iphone 6 Plus landscape
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
  .Header .Header-content {
    padding: 4rem 7rem 0rem 3rem;
    & .Header-hero h1 {
      font-size: 40px;
    }
  }
  #fistBox {
    padding-top: 0px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .Header .Header-content {
    padding: 3rem 7rem 0rem 3rem;
    &  .Header-hero h1 {
      font-size: 40px;
    }
  }

  #fistBox {
    padding-top: 0px;
  }
}

//Standard devices landscape
@media only screen and (min-device-width: 480px) and (max-device-width: 640px) and (orientation: landscape) {
  .Header .Header-content {
    padding: 3rem 7rem 0rem 3rem;

    & .Header-hero h1 {
      font-size: 40px;
    }
  }

  #fistBox {
    padding-top: 0px;
  }
}

/* Pixel 2 Landscape */
@media screen and (device-width: 412px) and (device-height: 823px) and (-webkit-device-pixel-ratio: 3.5) and (orientation: landscape) {
  .Header .Header-content {
    padding: 3rem 7rem 0rem 3rem;

    & .Header-hero h1 {
      font-size: 40px;
    }
  }

  #fistBox {
    padding-top: 0px;
  }
}

/* iPhone X in landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .Header .Header-content {
    padding: 2rem 13rem 0rem 3rem;

    & .Header-hero h1 {
      font-size: 42px;
    }
  }

  #fistBox {
    padding-top: 0px;
  }
}

/* iPhone 6, 7, & 8 in landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
  
  .Header .Header-content .Header-hero h1 {
    font-size: 40px;
  }

  #fistBox {
    padding-top: 0px;
  }
}


/* iPhone 6 + in portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    #fistBox {
      padding-top: 10rem;
    }
}

/* iPhone X in portrait */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
  #fistBox {
    padding-top: 11rem;
  }
}