@tailwind base;
@tailwind components;

@import "styles";
@import "_menu";
@import "_hero";
@import "_second";
@import "_testimonials";
@import "_request";
@import "_footer";

@tailwind utilities;
