section#about {
  font-family: Helvetica;
  #title {
    font-size: 24px;
  }

  .subtitle{
    @apply px-12 text-base;
    @screen lg {
			@apply px-20;
		}
  }

  #firstBox {
    h3 {
      @apply text-brokersGray;
    }
  }

  h5 {
    @apply px-5 text-brokersBlack font-bold py-4;
  }

  p {
    font-size: 14px;
    @apply px-10 text-brokersGray leading-relaxed;
    &.first {
      @apply px-12;
      
    }
  }
}
